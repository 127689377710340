import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ValueAccessorBase } from '@shared';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'ps-permission-select-list',
  templateUrl: './permission-select-list.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: PermissionSelectListComponent, multi: true }]
})
export class PermissionSelectListComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
  @Output()
  loaded = new EventEmitter(true);
  @Input()
  public readOnly = false;

  private subscription: Subscription;
  private _loaded = false;
  private deferredValue: string;

  constructor(
    private permissionsService: PermissionsService) {
      super();
    }

  ngOnInit(): void {
      this.subscription = this.permissionsService.permissions$
        .pipe(filter(permissions => !!permissions))
        .subscribe(() => {
          if (!this._loaded) {
            this.loaded.next(true);
            this._loaded = true;

            if (this.deferredValue) {
              this.writeValue(this.deferredValue);
            }
          }
        });

      this.permissionsService.loadPermissions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(value: string) {
    if (!this._loaded) {
      this.deferredValue = value;
    } else {
      super.writeValue(value);
    }
  }

  public get permissions$(): Observable<string[]> {
    return this.permissionsService.permissions$;
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key.toUpperCase() === 'A') {
      event.stopPropagation();
      event.preventDefault();
    }
 }
}
