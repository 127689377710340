import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { AdminMenuComponent } from './admin-menu';
import { UsersComponent } from './users';
import { RolePermissionsComponent } from './role-permissions';
import { RoleSelectListComponent, CreateRoleComponent } from './roles';
import { PermissionSelectListComponent } from './permissions';
import { AdminRoutingModule } from './admin-routing.module';
import { RemoveRoleDialogComponent } from './role-permissions/remove-role-dialog/remove-role-dialog.component';
import { FullNamePipe } from './users/full-name.pipe';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AdminMenuComponent,
    UsersComponent,
    RolePermissionsComponent,
    RoleSelectListComponent,
    PermissionSelectListComponent,
    CreateRoleComponent,
    RemoveRoleDialogComponent,
    FullNamePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AdminRoutingModule,
    MatListModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule
  ],
  exports: [
    AdminMenuComponent
  ]
})
export class AdminModule {
}
