import { Component, ElementRef, ViewChild, Output, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { RolesService } from '../roles.service';
import { EventEmitter } from '@angular/core';
import { Role } from '../role.model';

@Component({
  selector: 'ps-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent {

  public isRoleNameValid = true;

  private _textBoxShown = false;

  public get textBoxShown() {
    return this._textBoxShown;
  }

  @Input()
  public set textBoxShown(value: boolean) {
    if (value) {
      this.applyFocus();
    }

    if (value !== this._textBoxShown) {
      this.textBoxShownChange.emit(value);
    }

    this._textBoxShown = value;
  }

  @Output() busy: EventEmitter<boolean> = new EventEmitter();
  @Output() selectRole: EventEmitter<Role> = new EventEmitter();

  @Output() textBoxShownChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('inputRole') inputRole: ElementRef;

  constructor(
    private rolesService: RolesService) { }

  public applyFocus(): void {
    setTimeout(() => {
      if (this.inputRole) {
        this.inputRole.nativeElement.focus();
      }
    });
  }

  public removeTextBox(): void {
    this.textBoxShown = false;
    this.isRoleNameValid = true;
    this.textBoxShown = false;
  }

  public checkRoleNameValid(role: string): void {
    this.isRoleNameValid = !this.rolesService.containsRole(role);
  }

  public createRole(role: string): void {
    this.checkRoleNameValid(role);
    if (this.isRoleNameValid) {
      this.busy.emit(true);
      this.rolesService.createRole(role)
        .pipe(
          first()
        )
        .subscribe(createdRole => {
          this.busy.emit(false);
          this.removeTextBox();
          this.selectRole.emit(createdRole);
        },
        err => {
          this.busy.emit(false);
          console.log('Role creation failed', err);
        });
    }
  }
}
