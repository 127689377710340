<div class="landing_content">
  <div class="sign-in">
    <h3>Sign in to the Hub</h3>
    <p>To access Dojo Hub, please sign in with your company email address</p>
  </div>
  <div class="sign-in-actions">
    <dj-button flex="true" (click)="signIn()" accessiblelabel="Sign in">
      Sign in
    </dj-button>
  </div>
</div>
