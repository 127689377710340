import { ControlValueAccessor } from '@angular/forms';

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
    private innerValue: T;

    private changed: Array<(value: T) => void> = [];
    private touched: Array<() => void> = [];

    public get value(): T {
        return this.innerValue;
    }

    public set value(updated: T) {
        if (this.innerValue === updated) {
            return;
        }

        this.innerValue = updated;
        this.changed.forEach(f => f(this.value));
    }

    public touch() {
        this.touched.forEach(f => f());
    }

    public writeValue(value: T) {
        this.innerValue = value;
    }

    public registerOnChange(fn: (value: T) => void) {
        this.changed.push(fn);
    }

    public registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }
}
