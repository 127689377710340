<mat-progress-bar mode="indeterminate" [ngStyle]="{ 'visibility': busy ? 'visible': 'hidden' }"></mat-progress-bar>
<div class="users-container">
  <mat-card class="card">
    <mat-card-title>
      Users
    </mat-card-title>
    <mat-card-content>
        <form (ngSubmit)="search(searchInput.value)" #searchForm="ngForm" class="search-form" autocomplete="off">
          <mat-form-field>
            <input matInput placeholder="Search for users..." #searchInput psAutoFocus (blur)="search(searchInput.value)">
          </mat-form-field>
          <button mat-mini-fab color="primary" [disabled]="searchInput.value === lastSearchQuery">
            <mat-icon>search</mat-icon>
          </button>
        </form>
        <mat-list id="user-list">
          <mat-list-item 
            *ngFor="let user of users$ | async" 
            [ngClass]="{'selected': selectedUser && user.providerId === selectedUser.providerId }"
            (click)="selectUser(user)">
            {{user | fullName}}
          </mat-list-item>
        </mat-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button (click)="movePrev()" [disabled]="!canMovePrev" class="move-prev">&lt; PREV</button>
        <button mat-button (click)="moveNext()" [disabled]="!canMoveNext" class="move-next">NEXT &gt;</button>  
    </mat-card-actions>
  </mat-card>
  <mat-card class="card">
    <mat-card-title>
      <mat-tab-group [(selectedIndex)]="selectedTabIndex" id="roles-permissions-tab">
        <mat-tab label="Roles"></mat-tab>
        <mat-tab label="Permissions"></mat-tab>
      </mat-tab-group>
    </mat-card-title>
    <mat-card-content>

      <ps-role-select-list
        *ngIf="userPrivileges$ | async as userPrivileges"
        [hidden]="selectedTabIndex !== 0"
        [(ngModel)]="userPrivileges.roles"
        (ngModelChange)="updateUserPrivileges(userPrivileges)"
        [readOnly]="!canEdit"
        (loaded)="rolesLoaded = true"
        id="roles-list"
        name="roles-list">
      </ps-role-select-list>

      <ps-permission-select-list
        *ngIf="userPrivileges$ | async as userPrivileges"
        [hidden]="selectedTabIndex !== 1"
        [(ngModel)]="userPrivileges.permissions"
        (ngModelChange)="updateUserPrivileges(userPrivileges)"
        [readOnly]="!canEdit"
        (loaded)="permissionsLoaded = true"
        id="permissions-list"
        name="permissions-list">
      </ps-permission-select-list>

    </mat-card-content>
  </mat-card>
  <ps-loader *ngIf="loading"></ps-loader>
</div>
