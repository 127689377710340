import { AuthenticatorService } from '@paymentsense/auth-provider';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PermissionEnum } from '@core';
import { UserDetail } from './user-detail.model';
import { UserDetailsService } from './user-details.service';
import { UserPrivileges } from './user-privileges.model';
import { UserPrivilegesService } from './user-privileges.service';

@Component({
  selector: 'ps-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public selectedUser: UserDetail;
  public canEdit = false;
  public selectedTabIndex = 0;
  public lastSearchQuery = '';

  private loadCount = 0;
  private busyCount = 0;
  private rolesLoaded = false;
  private permissionsLoaded = false;
  private userSubscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private authService: AuthenticatorService,
    private userPrivilegesService: UserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.authService.user$.subscribe(
      user =>
        (this.canEdit =
          user && user.permissions && user.permissions.some(p => p === PermissionEnum.AdminUserPrivilegesEdit))
    );

    this.trackUserLoad(this.userDetailsService.loadFirst());
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public get users$(): Observable<UserDetail[]> {
    return this.userDetailsService.users$;
  }

  public get userPrivileges$(): Observable<UserPrivileges> {
    return this.selectedUser ? this.userPrivilegesService.userPrivileges$ : null;
  }

  public get loading(): boolean {
    return !!this.loadCount;
  }

  public get busy(): boolean {
    return !this.loading && (!!this.busyCount || !this.rolesLoaded || !this.permissionsLoaded);
  }

  public get canMoveNext(): boolean {
    return this.userDetailsService.canMoveNext;
  }

  public get canMovePrev(): boolean {
    return this.userDetailsService.canMovePrev;
  }

  public moveNext(): void {
    this.trackUserLoad(this.userDetailsService.moveNext());
  }

  public movePrev(): void {
    this.trackUserLoad(this.userDetailsService.movePrev());
  }

  public selectUser(user: UserDetail) {
    this.selectedUser = user;
    this.selectedTabIndex = 0;

    if (user) {
      this.trackBusy(this.userPrivilegesService.get(user.providerId), 'getting user privileges');
    }
  }

  public updateUserPrivileges(userPrivileges: UserPrivileges) {
    this.trackBusy(this.userPrivilegesService.update(userPrivileges), 'saving user privileges');
  }

  public search(searchQuery: string) {
    if (this.lastSearchQuery !== searchQuery) {
      this.lastSearchQuery = searchQuery;
      this.trackUserLoad(this.userDetailsService.search(searchQuery));
    }
  }

  private trackUserLoad(observable: Observable<UserDetail[]>): void {
    this.loadCount++;

    observable.pipe(first()).subscribe(
      users => {
        this.selectUser(users && users.length ? users[0] : null);
        this.loadCount--;
      },
      err => {
        this.loadCount--;
        console.log('Error loading users', err);
      }
    );
  }

  private trackBusy<T>(observable: Observable<T>, taskDescription: string): void {
    this.busyCount++;
    observable.pipe(first()).subscribe(
      () => this.busyCount--,
      err => {
        this.busyCount--;
        console.log(`Error ${taskDescription}`, err);
      }
    );
  }
}
