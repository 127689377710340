import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'ps-remove-role-dialog',
  templateUrl: './remove-role-dialog.component.html'
})
export class RemoveRoleDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data) {}

}
