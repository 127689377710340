<a class="mat-card-clickable" href="{{site.url}}">
  <mat-card id="site_{{site.name}}">

        <mat-card-content>
            <div class="mat-card-column">
              <img mat-card-image src="{{site.imageUrl}}" alt="Site photo">
            </div>
            <div>
              <div class="mat-card-title">{{site.name}}</div>
              <div class="mat-card-description">{{site.description}}</div>
            </div>
        
        </mat-card-content>
        <mat-card-actions>
          <a href="{{site.url}}"><button mat-button></button></a>
        </mat-card-actions>

  </mat-card>
</a>