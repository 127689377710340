import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthRoutes, PermissionEnum } from '@core';
import { AuthorizedGuard, UnauthorizedComponent } from '@paymentsense/auth-provider';
import { HomeComponent } from './home';
import { LandingComponent } from './landing';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthorizedGuard],
    data: {
      permissions: [PermissionEnum.HubAccessView]
    }
  },
  {
    path: AuthRoutes.Landing,
    component: LandingComponent
  },
  {
    path: AuthRoutes.Unauthorized,
    component: UnauthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
