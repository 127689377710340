import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserPrivileges } from './user-privileges.model';
import { UserPrivilegesApiService } from './user-privileges-api.service';

@Injectable({
    providedIn: 'root'
})
export class UserPrivilegesService {
  private userPrivilegesSubject = new BehaviorSubject<UserPrivileges>(null);

  constructor(
    private userPrivilegesApiService: UserPrivilegesApiService) {
  }

  public get userPrivileges$(): Observable<UserPrivileges> {
    return this.userPrivilegesSubject.asObservable();
  }

  public get(userId: string): Observable<UserPrivileges> {
    if (userId) {
      return this.userPrivilegesApiService.get(userId)
        .pipe(
          tap(userPrivileges => this.userPrivilegesSubject.next(userPrivileges || { userId, permissions: [], roles: [] } as UserPrivileges))
        );
    } else {
      this.userPrivilegesSubject.next(null);
      return of(null);
    }
  }

  public update(userPrivileges: UserPrivileges): Observable<UserPrivileges> {
    this.userPrivilegesSubject.next(userPrivileges);
    return this.userPrivilegesApiService.update(userPrivileges);
  }
}
