import { Component, Input } from '@angular/core';

import { Site } from '../site.model';

@Component({
  selector: 'ps-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent {

  @Input()
  public site: Site;

  constructor() { }
}
