
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpServerErrorInterceptor implements HttpInterceptor {
    constructor(
      private matSnackBar: MatSnackBar) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(errorResponse => {
            if (errorResponse instanceof HttpErrorResponse) {
              if (errorResponse.status === 500) {
                this.matSnackBar.open(`${errorResponse.statusText}: ${errorResponse.message}` , 'Dismiss');
              }
            }

            return throwError(errorResponse);
        }));
    }
}
