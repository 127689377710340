import { NgModule } from '@angular/core';
import { environment } from '@env/environment';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
  BrowserAnimationsModule,
  NoopAnimationsModule
} from '@angular/platform-browser/animations';

const animationModule = environment.animate ? BrowserAnimationsModule : NoopAnimationsModule;

@NgModule({
    imports: [
      MatButtonModule,
      MatToolbarModule,
      MatCardModule,
      MatGridListModule,
      MatMenuModule,
      MatIconModule,
      animationModule,
      MatProgressSpinnerModule
    ],
    exports: [
      MatButtonModule,
      MatToolbarModule,
      MatCardModule,
      MatGridListModule,
      MatMenuModule,
      MatIconModule,
      animationModule,
      MatProgressSpinnerModule
    ],
    providers: [
    ]
})
export class MaterialModule { }
