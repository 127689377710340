import { Pipe, PipeTransform } from '@angular/core';
import { UserDetail } from './user-detail.model';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(user: UserDetail): string {
    if (!user) {
      return '';
    }

    const { firstName, lastName } = user;
    return [firstName, lastName]
      .filter(name => name)
      .join(' ');
  }

}
