import { RolesApiService } from './roles-api.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, of, BehaviorSubject} from 'rxjs';
import { tap, switchMap} from 'rxjs/operators';
import { Role } from './role.model';

@Injectable({
    providedIn: 'root'
})
export class RolesService implements OnDestroy {
  private rolesSubject = new BehaviorSubject<Role[]>(null);

  roles$: Observable<Role[]>;
  private getRolesSubscription: Subscription;

  constructor(private rolesApiService: RolesApiService) {

    this.roles$ = this.rolesSubject.asObservable();
  }

  public containsRole(role: string): boolean {
    if (role === '') {
       return false;
    }
    const formattedRole = this.formatRole(role);

    const roles = this.rolesSubject.value;
    const returnVal = roles && roles.some(r => this.formatRole(r.id) === formattedRole);
    return returnVal;
  }

  private formatRole(role: string): string {
    return role.replace(/\s/g, '').toLowerCase();
  }

  public loadRoles(): void {
    if (this.getRolesSubscription) {
      return;
    }

    this.getRolesSubscription = this.rolesApiService.getRoles().subscribe(roles => {
      this.rolesSubject.next(roles.sort((a, b) => a.id.localeCompare(b.id)));
    });
  }

  public updateRole(role: Role): Observable<Role> {
    const encodedRole = encodeURIComponent(role.id);

    const putRole: Role = {
        id: encodedRole,
        permissions: role.permissions
    };

    return this.rolesApiService.updateRole(putRole)
      .pipe(
        tap(updatedRole => {
          const roles = this.rolesSubject.value;
          const index = roles.findIndex(r => r.id === updatedRole.id);
          roles.splice(index, 1, updatedRole);
          this.rolesSubject.next(roles);
        })
      );
  }

  public createRole(role: string): Observable<Role> {
    const formattedRole = role.trim().toLowerCase();

    const postRole: Role = {
      id: formattedRole,
      permissions: []
    };

    return this.rolesApiService.createRole(postRole)
      .pipe(
        tap(() => {
          const roles = this.rolesSubject.value;
          roles.push(postRole);
          this.rolesSubject.next(roles.sort((a, b) => a.id.localeCompare(b.id)));
        }),
        switchMap(() => of(postRole))
      );
  }

  public removeRole(roleId: string): Observable<void> {
    return this.rolesApiService.removeRole(roleId)
      .pipe(
        tap(() => {

        const roles = this.rolesSubject.value;

        roles.splice(roles.findIndex(i => i.id === roleId), 1);
        if (roles.length === 0) {
          this.rolesSubject.next(null);
        } else {
          this.rolesSubject.next(roles);
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this.getRolesSubscription) {
      this.getRolesSubscription.unsubscribe();
    }
  }
}
