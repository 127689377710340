import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as LogRocket from 'logrocket';
import { AuthenticatorService } from '@paymentsense/auth-provider';

import { ConfigService } from '../config';

@Injectable({
  providedIn: 'root'
})
export class LogRocketService implements OnDestroy {

  private readonly destroy$ = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private authService: AuthenticatorService) {

    if (this.configService.logRocketAppId) {
      LogRocket.init(this.configService.logRocketAppId);

      this.authService.user$.pipe(
          filter(user => !!user),
          takeUntil(this.destroy$)
        )
        .subscribe(user => {
          LogRocket.identify(user.id, {
            name: user.name || user.email,
            email: user.email
          });
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
