import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UsersComponent } from './users';
import { RolePermissionsComponent } from './role-permissions';
import { AdminRoutes } from './admin-routes';
import { PermissionEnum } from '@core';
import { AuthorizedGuard } from '@paymentsense/auth-provider';

const routes: Routes = [
  {
    path: AdminRoutes.Users,
    component: UsersComponent,
    canActivate: [AuthorizedGuard],
    data: { permissions: [PermissionEnum.AdminUserPrivilegesView] }
  },
  {
    path: AdminRoutes.Permissions,
    component: RolePermissionsComponent,
    canActivate: [AuthorizedGuard],
    data: { permissions: [PermissionEnum.AdminRolesAndPermissionsView] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
