<div class="permissions-container">
  <mat-progress-bar mode="indeterminate" [ngStyle]="{ 'visibility': busy ? 'visible': 'hidden' }"></mat-progress-bar>
  <div class="permissions-content" *ngIf="loaded">
    <mat-card class="card">
      <mat-card-title>
        Roles
      </mat-card-title>
      <span>
        <button mat-raised-button color="primary" id="createRoleButton"
          [psAuthEnableIfPermitted]="PermissionsList.AdminRolesAndPermissionsEdit" 
          (click)="showTextBox()">Create Role
        </button>
        <button mat-raised-button color="warn" class="remove" [disabled]="!canRemove" (click)="removeRole()">
          Remove Role
        </button>
        <ps-create-role
          [(textBoxShown)]="showCreateRole"
          (busy)="changeBusyCount($event)"
          (selectRole)="selectRole($event)">
        </ps-create-role>
      </span>
      <mat-nav-list id="roles-list">
        <mat-list-item
          [ngClass]="{'selected': role.id === selectedRole?.id }"
          role="listitem" 
          (click)="selectRole(role)"
          *ngFor="let role of roles$ | async;">
          {{role.id}}
        </mat-list-item>            
      </mat-nav-list>   
    </mat-card>   
    <mat-card class="card">
      <mat-card-title>
        Permissions
      </mat-card-title>
      <ps-permission-select-list
        *ngIf="selectedRole"
        [(ngModel)]="selectedRole.permissions"
        (ngModelChange)="updateRole(selectedRole)"
        [readOnly]="!canEdit"
        (loaded)="permissionsLoaded = true"
        id="permissions-list"
        name="permissions-list">
      </ps-permission-select-list>
    </mat-card>
  </div>
  <ps-loader *ngIf="!loaded"></ps-loader>

</div>