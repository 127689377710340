import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from './role.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesApiService {
  constructor(private http: HttpClient) {}

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>('roles');
  }

  public updateRole(role: Role): Observable<Role> {
    return this.http.put<Role>(`roles/${role.id}`, role);
  }

  public createRole(role: Role): Observable<void> {
    return this.http.post<void>('roles', role);
  }

  public removeRole(roleId: string): Observable<void> {
    return this.http.delete<void>(`roles/${roleId}`);
  }
}
