import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared';
import { SiteListComponent } from './site-list';
import { SitesService } from './sites.service';
import { SiteComponent } from './site';

@NgModule({
  declarations: [
    SiteListComponent,
    SiteComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SiteListComponent
  ],
  providers: [
    SitesService
  ]
})
export class SitesModule {
}
