import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserDetailsApiService } from './user-details-api.service';
import { UserDetail } from './user-detail.model';
import { UserDetailQuery } from './user-detail-query.model';

@Injectable({
    providedIn: 'root'
})
export class UserDetailsService {
  private static PageSize = 20;

  private userSubject = new BehaviorSubject<UserDetail[]>([]);
  public loading = false;
  private pageTokens: string[];
  private currentPageIndex: number;

  constructor(
    private userDetailsApiService: UserDetailsApiService) {

    this.initPageTokens();
  }

  public get users$(): Observable<UserDetail[]> {
    return this.userSubject.asObservable();
  }

  public get canMoveNext() {
    return !this.loading && !!this.pageTokens[this.currentPageIndex + 1];
  }

  public get canMovePrev() {
    return !this.loading && this.currentPageIndex > 0;
  }

  public loadFirst(): Observable<UserDetail[]> {
    this.initPageTokens();
    return this.loadUsers(0);
  }

  public moveNext(): Observable<UserDetail[]> {
    return this.loadUsers(this.currentPageIndex + 1);
  }

  public movePrev(): Observable<UserDetail[]> {
    return this.loadUsers(this.currentPageIndex - 1);
  }

  public search(searchQuery: string): Observable<UserDetail[]> {
    this.initPageTokens();
    return this.loadUsers(0, searchQuery);
  }

  private loadUsers(pageIndex: number, searchQuery?: string): Observable<UserDetail[]> {
    this.loading = true;

    const query: UserDetailQuery = {
      queryValue: searchQuery,
      maxResults: UserDetailsService.PageSize,
      pageToken: this.pageTokens[pageIndex]
    };

    return this.userDetailsApiService.getUserDetails(query)
      .pipe(
        tap(paginatedList => {
          this.userSubject.next(paginatedList.users);
          this.currentPageIndex = pageIndex;
          this.pageTokens[pageIndex + 1] = paginatedList.nextPageToken;
          this.loading = false;
        }),
        map(paginatedList => paginatedList.users)
      );
  }

  private initPageTokens(): void {
    this.pageTokens = [null];
  }
}
