import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  HttpUnauthorizedInterceptor,
  HttpAuthorizationHeaderInterceptor,
  AuthenticationConfig,
  AuthenticationModule
} from '@paymentsense/auth-provider';
import { AuthRoutes } from './auth';
import { HttpServerErrorInterceptor, HttpUrlInterceptor } from './http';
import { Config } from './config/config.model';
import { SimpleHttpParamEncoder } from './http/simple-http-param-encoder';
import { AuthModule } from '@auth0/auth0-angular';

export let config: Config;

export function getConfig(): Config {
  if (!config) {
    throw new Error('config has not been set');
  }

  return config;
}

export function getAuthConfig(): AuthenticationConfig {
  return getConfig().auth;
}

export function initAuthConfig() {
  // tslint:disable-next-line: only-arrow-functions
  return async function() {
    const res = await fetch('assets/config/config.json');
    config = await res.json();
    config.auth.unauthenticatedRedirectUrl = `/${AuthRoutes.Landing}`;
    config.auth.unauthorizedRedirectUrl = `/${AuthRoutes.Unauthorized}`;
    config.auth.permissionsEndpoint = `userpermissions`;
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    AuthenticationModule.forRoot(getAuthConfig),
    AuthModule.forRoot({
      domain: '',
      clientId: ''
    })
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initAuthConfig, multi: true },
    { provide: Config, useFactory: getConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthorizationHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUnauthorizedInterceptor, multi: true },

    SimpleHttpParamEncoder
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
