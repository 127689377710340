import {Injectable} from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config';


@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^https?\:\/\//.test(request.url)) {
      let baseUrl = this.configService.hubApiUrl;

      if (!baseUrl.endsWith('/') && !request.url.startsWith('/')) {
        baseUrl += '/';
      }

      const url = `${baseUrl}${request.url}`;

      request = request.clone({ url });
    }

    return next.handle(request);
  }
}
