import { Injectable } from '@angular/core';
import { Config } from './config.model';
import { BackgroundVersionValidationConfig } from '@paymentsense/background-version-validation';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    constructor(private config: Config) { }

    get hubApiUrl(): string {
        return this.config.hubApiUrl;
    }

    get getBackgroundVersionValidationConfig(): BackgroundVersionValidationConfig {
      return this.config.backgroundVersionValidationConfig;
    }

    get logRocketAppId(): string {
      return this.config.logRocketAppId;
    }
}
