<div class="flex-row" *ngIf="textBoxShown">
  <mat-form-field id="createRoleForm" class="createRoleForm">
    <div class="flex-input">
      <input matInput placeholder="Role Name" #inputRole id="inputRole" class="inputRole"
        (keyup)="checkRoleNameValid(inputRole.value)"
        (keyup.escape)="removeTextBox()" 
        (keyup.enter)="createRole(inputRole.value)">
      <mat-error *ngIf="!isRoleNameValid" id="createRoleError" class="createRoleError">
        Cannot be empty or exist already
      </mat-error>
    </div>
  </mat-form-field>
  <span>
    <button mat-flat-button color="primary" class="confirm"  id="confirmButton"
      (click)="createRole(inputRole.value)">Confirm</button>
    <button mat-flat-button class="cancel" color="warn" id="cancelButton" 
      (click)="removeTextBox()">Cancel</button>
  </span>
</div>
