import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsApiService {

  constructor(
    private http: HttpClient) {
    }

  public getPermissions(): Observable<string[]> {
    return this.http.get<string[]>('permissions');
  }
}
