import { PermissionsApiService } from './permissions-api.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService implements OnDestroy {

  private permissionsSubject = new BehaviorSubject<string[]>(null);
  permissions$: Observable<string[]> = this.permissionsSubject.asObservable();
  private getPermissionsSubscription: Subscription;

  constructor(
    private permissionsApiService: PermissionsApiService) {
  }

  loadPermissions(): void {
    if (this.getPermissionsSubscription) {
      return;
    }

    this.getPermissionsSubscription = this.permissionsApiService.getPermissions().subscribe(permissions => {
      this.permissionsSubject.next(permissions.sort());
    });
  }

  ngOnDestroy(): void {
    if (this.getPermissionsSubscription) {
      this.getPermissionsSubscription.unsubscribe();
    }
  }
}
