import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatorService, User } from '@paymentsense/auth-provider';
import { LoginService } from '@paymentsense/nav-components';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ps-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthenticatorService,
    private loginService: LoginService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authService.user$
      .pipe(
        filter((user: User) => !!user),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.router.navigate(['/']));
  }

  signIn() {
    this.loginService.signIn();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
