import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Site } from '../site.model';
import { SitesService } from '../sites.service';

@Component({
  selector: 'ps-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent {

  public get sites$(): Observable<Site[]> {
    return this.sitesService.sites$;
  }

  constructor(private sitesService: SitesService) {
  }
}
