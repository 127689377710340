import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { AuthenticationModule } from '@paymentsense/auth-provider';
import { AutofocusDirective } from './auto-focus';
import { LoaderComponent } from './loader';
import { DjButtonModule } from '@paymentsense/dj-components-angular';

@NgModule({
  declarations: [
    AutofocusDirective,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DjButtonModule,
  ],
  exports: [
    MaterialModule,
    AutofocusDirective,
    AuthenticationModule,
    LoaderComponent,
    DjButtonModule,
  ],
  providers: []
})
export class SharedModule {}
