import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule, AuthRoutes } from '@core';
import { SharedModule } from '@shared';
import { NavModule } from '@paymentsense/nav-components';
import { HomeModule } from './home';
import { SitesModule } from './sites';
import { LandingModule } from './landing';

import { AppComponent } from './app.component';
import { AdminModule } from './admin';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BackgroundVersionValidationModule, BackgroundVersionValidationConfig } from '@paymentsense/background-version-validation';
import { getConfig } from './core/core.module';
import { UITestMockModule } from './ui-test-mock.module';

export function getBackgroundVersionValidationConfig(): BackgroundVersionValidationConfig {
  return getConfig().backgroundVersionValidationConfig;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BackgroundVersionValidationModule.forRoot(getBackgroundVersionValidationConfig),
    NavModule.forRoot({
      signInRedirectUrl: '/',
      signOutRedirectUrl: `/${AuthRoutes.Landing}`,
      firebaseLandingUrl: `/${AuthRoutes.FirebaseLanding}`,
      sitesConfigUrl : environment.config.sitesConfigUrl,
      gSuiteLoginEnabled: true,
      emailLoginEnabled: false
    }),
    CoreModule,
    SharedModule,
    HomeModule,
    SitesModule,
    LandingModule,
    AdminModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    }),
    // should be last so can override services
    UITestMockModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
