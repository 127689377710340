import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserDetailQuery } from './user-detail-query.model';
import { UserDetailPaginatedList } from './user-detail-paginated-list.model';

import { SimpleHttpParamEncoder } from '../../core/http/simple-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsApiService {

  constructor(
    private http: HttpClient,
    private encoder: SimpleHttpParamEncoder) {
  }

  public getUserDetails(query: UserDetailQuery): Observable<UserDetailPaginatedList> {
    const params = new HttpParams({
      fromObject: this.toParams(query),
      encoder: this.encoder
    });
    return this.http.get<UserDetailPaginatedList>('userdetails', { params });
  }

  private toParams(obj: any): { [param: string]: string; } {
    return Object.keys(obj)
      .filter(key => obj[key] !== null && obj[key] !== undefined)
      .reduce((params, key) => {
        params[key] = obj[key].toString();
        return params;
      }, {});
  }
}


