import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ValueAccessorBase } from '@shared';
import { Role } from '../role.model';
import { RolesService } from '../roles.service';

@Component({
  selector: 'ps-role-select-list',
  templateUrl: './role-select-list.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RoleSelectListComponent, multi: true }]
})
export class RoleSelectListComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
  @Output()
  loaded = new EventEmitter(true);
  @Input()
  public readOnly = false;

  private subscription: Subscription;
  private _loaded = false;
  private deferredValue: string;

  constructor(
    private rolesService: RolesService) {
      super();
    }

  ngOnInit(): void {
      this.subscription = this.rolesService.roles$
        .pipe(filter(roles => !!roles))
        .subscribe(() => {
          if (!this._loaded) {
            this.loaded.next(true);
            this._loaded = true;

            if (this.deferredValue) {
              this.writeValue(this.deferredValue);
            }
          }
        });

      this.rolesService.loadRoles();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(value: string) {
    if (!this._loaded) {
      this.deferredValue = value;
    } else {
      super.writeValue(value);
    }
  }

  public get roles$(): Observable<Role[]> {
    return this.rolesService.roles$;
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key.toUpperCase() === 'A') {
      event.stopPropagation();
      event.preventDefault();
    }
 }
}
