import { AuthenticationConfig } from '@paymentsense/auth-provider';
import { BackgroundVersionValidationConfig } from '@paymentsense/background-version-validation';

export class Config {
  hubApiUrl: string;
  sitesConfigUrl: string;
  auth: AuthenticationConfig;
  backgroundVersionValidationConfig: BackgroundVersionValidationConfig;
  logRocketAppId: string;
}
