import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Site } from './site.model';
import { NavSiteService } from '@paymentsense/nav-components';

@Injectable()
export class SitesService {
  private static readonly ExcludedSites = ['hub', 'onboarding', 'quote', 'search', 'partners'];

  public readonly sites$: Observable<Site[]>;

  constructor(
    private navSiteService: NavSiteService) {
      this.sites$ = this.navSiteService.sites$
        .pipe(
          map(sites => sites ? sites.filter(site => !SitesService.ExcludedSites.includes(site.id)) : sites)
        );
  }
}
