import { Component } from '@angular/core';
import { LogRocketService, PermissionEnum } from '@core';
import { MenuGroup } from '@paymentsense/nav-components';
import { AdminRoutes } from './admin/admin-routes';

@Component({
    selector: 'ps-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

  adminMenu: MenuGroup[] = [{
    title: 'Admin',
    id: 'admin',
    menuItems: [{
      id: 'user-management',
      title: 'User Management',
      url: `/${AdminRoutes.Users}`,
      permission: PermissionEnum.AdminUserPrivilegesView,
    }, {
      id: 'permissions-management',
      title: 'Permissions Management',
      url: `/${AdminRoutes.Permissions}`,
      permission: PermissionEnum.AdminRolesAndPermissionsView,
    }]
  }];

  constructor(
    private logRocketService: LogRocketService,
  ) {}
}
