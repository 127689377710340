import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil, filter } from 'rxjs/operators';
import { PermissionEnum } from '@core';
import { Role } from '../roles/role.model';
import { RolesService } from '../roles/roles.service';
import { AuthenticatorService, User } from '@paymentsense/auth-provider';
import { MatDialog } from '@angular/material/dialog';
import { RemoveRoleDialogComponent } from './remove-role-dialog/remove-role-dialog.component';

@Component({
  selector: 'ps-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['role-permissions.component.scss']
})
export class RolePermissionsComponent implements OnInit, OnDestroy {
  public selectedRole: Role;
  public loaded = false;
  public busyCount = 0;
  public canEdit = false;
  public permissionsLoaded = false;
  private canRemoveRole = false;
  public showCreateRole = false;

  private subscriptions: Subscription[] = [];
  private unsubscribeComponent$: Subject<any> = new Subject();

  constructor(
    private rolesService: RolesService,
    private authService: AuthenticatorService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.rolesService.roles$.pipe(filter(roles => !!roles)).subscribe(roles => {
        if (!this.loaded || !this.selectedRole) {
          this.selectRole(roles.length ? roles[0] : null);
        }

        this.loaded = true;
      })
    );

    this.subscriptions.push(
      this.authService.user$.subscribe(user => {
        this.canEdit = this.hasPermission(user, PermissionEnum.AdminRolesAndPermissionsEdit);
        this.canRemoveRole = this.hasPermission(user, PermissionEnum.AdminRolesDelete);
      })
    );

    this.rolesService.loadRoles();
  }

  public get PermissionsList() {
    return PermissionEnum;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.unsubscribeComponent$.next();
    this.unsubscribeComponent$.complete();
  }

  public showTextBox() {
    this.showCreateRole = true;
  }

  private hasPermission(user: User, permission: string): boolean {
    return user && user.permissions && user.permissions.some(p => p === permission);
  }

  public get busy(): boolean {
    return !!this.busyCount || (!this.permissionsLoaded && !!this.selectedRole);
  }

  public get roles$(): Observable<Role[]> {
    return this.rolesService.roles$;
  }

  public get canRemove(): boolean {
    return this.canRemoveRole && this.selectedRole != null;
  }

  public selectRole(role: Role): void {
    this.selectedRole = role;
  }

  public changeBusyCount(isBusy: boolean) {
    this.busyCount = isBusy ? this.busyCount++ : this.busyCount--;
  }

  public updateRole(role: Role) {
    this.busyCount++;
    this.rolesService
      .updateRole(role)
      .pipe(first())
      .subscribe(() => this.busyCount--);
  }

  public removeRole() {
    const role = this.selectedRole;
    if (!role) {
      return;
    }
    const dialogRef = this.dialog.open(RemoveRoleDialogComponent, {
      data: { name: role.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRoleFromService(role.id);
        this.selectedRole = null;
      }
    });
  }

  private removeRoleFromService(roleId: string) {
    this.busyCount++;
    this.rolesService
      .removeRole(this.selectedRole.id)
      .pipe(takeUntil(this.unsubscribeComponent$))
      .subscribe(
        () => {
          this.busyCount--;
        },
        err => {
          this.busyCount--;
          console.log('Error removing role', err);
        }
      );
  }
}
